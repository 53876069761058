/*
 * Home
 */

//==================================================
//                    IMPORTS
//==================================================

@import 'components/functions'
@import 'components/variables'
@import 'components/mixins'
@import 'components/utilities'


//==================================================
//                     HEADER
//==================================================

@import "elements/header/image"

//==================================================
//                    CUSTOM
//==================================================

.elements-promotion-header
	h1
		font-weight: 600
		.emphasis
			display: block
			font-weight: 700
		+media-breakpoint-up('sm')
			.emphasis
				font-size: 4rem

	+media-breakpoint-up('lg')
		height: 60vh
	+media-breakpoint-up('xl')
		height: 90vh

	.company-start-year
		font-size: $h4-font-size

[class*="elements-header-"]

	&,
	.swiper-container .swiper-slide

		height: calc(100vh - 76px)

		@media screen and (min-width: #{map_get($grid-breakpoints, sm)})
			height: 50vh

//Header
.company-start-year
	font-family: $raleway


.feature--with-icon
	line-height: 1

	//Blend into the background
	img
		opacity: 0.5
	.small, .feature__statistic, .feature__statistic-label
		opacity: 0.6

.feature__statistic
	font-weight: 800

	//mobile size
	font-size: $h1-font-size

	//scale up font size
	+media-breakpoint-up('sm')
		font-size: $display3-size

//Force a line break
.feature__statistic-label
	max-width: 8rem

//Adjust line break in French
[lang="fr-CA"] .feature__statistic-label
	max-width: 11rem

	+media-breakpoint-down('lg')
		font-size: 1.5rem

.banner-link
	box-shadow: 1px 1px 2px rgba(0,0,0,0.3)

.banner-link__icon
	max-height: 3rem

.banner-link__content
	font-size: 14px
	flex-grow: 1
	p
		font-size: inherit
		text-align: inherit
		margin-bottom: 0

$banner-link-animation-speed: 0.2s
.banner-link__arrow
	color: #e3e3e3
	transition: color $banner-link-animation-speed ease-out

	//Come on arrow, lighten up a little
	-webkit-text-stroke: 4px white

a.banner-link
	&:hover, &:active
		text-decoration: none
		.banner-link__arrow
			color: theme-color('primary')
			transition: color $banner-link-animation-speed ease-in

//Awwwwww yeah, 3d animations on a Friday night
//Uncomment this block to make banner-links pop up on hover
.banner-link
	transition: box-shadow $banner-link-animation-speed ease-out, transform $banner-link-animation-speed ease-out
	transform: translateZ(0)
	backface-visibility: hidden
	*
		transform: translateZ(0)
		backface-visibility: hidden

.banner-link:hover
	box-shadow: 2px 2px 3px rgba(0,0,0,0.3)
	transform: scale(1.01) translateZ(0)
	transition: box-shadow $banner-link-animation-speed ease-in, transform $banner-link-animation-speed ease-in

.banner-link:active
	box-shadow: 1px 1px 1px rgba(0,0,0,0.3)
	transform: scale(1)
	transition: box-shadow $banner-link-animation-speed ease-out, transform $banner-link-animation-speed ease-out

//IE11 YOU POOR IGNORANT FOOL
	Why do you blink when I need you to transform?
	(Use this in conjunction with home.js Trident fix)
.banner-link.ie11
	transform: none
	&:hover
		transform: none

#facility .fa-ul
	font-weight: 600

#quick-tour .quick-tour__content-wrapper
	background-size: cover

	//background color overlay
	&::before
		content: ''
		display: block
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		background-color: rgba(0,0,0,0.5)

	//Increase space between heading, underline, and stuff below
	.light-heading
		padding-bottom: $h2-font-size
		margin-bottom: $h2-font-size

	p
		text-align: inherit
		line-height: 2
		hyphens: none

#tournaments
	position: relative

	//Trophy
	&::before
		position: absolute
		content: ''
		top: -3rem
		height: 100%
		width: 100%
		background-image: url('/assets/theme/img/graphics/trophy.png')
		background-repeat: no-repeat
		background-position: 30% center
		background-size: contain

		+media-breakpoint-down('sm')
			background-position: center center
			top: 0
			height: 90%

#package-promo-footer
	//room for bowling ball
	margin-top: 4rem

	border-top: 0.5rem solid $maroon
	position: relative

	//Ball
	&:before
		position: absolute
		content: ''
		width: 13rem
		height: 13rem
		top: -4rem
		left: 0
		background-image: url('/assets/theme/img/graphics/ball.png')
		background-repeat: no-repeat
		background-size: contain

		//Needs to be above content...
		z-index: 2
		//... but don't block content
		pointer-events: none

		//Position left of ad
		+media-breakpoint-up('lg')
			left: 59%

	//Dots
	&::after
		position: absolute
		content: ''
		width: 100%
		height: 3rem
		top: 0

		//background-image: url('/assets/theme/img/graphics/lane-dots.png')
		background-repeat: repeat-x
		background-position: 0 center


		background-image: radial-gradient(#420e00 10%, transparent 11%)
		background-size: 4rem 4rem
		+media-breakpoint-up('sm')
			background-image: radial-gradient(#420e00 5%, transparent 6%)
			background-size: 7rem 7rem
		+media-breakpoint-up('lg')
			background-image: radial-gradient(#420e00 5%, transparent 6%)


