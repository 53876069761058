/* Home */
/*!
 * Functions
 */
/*!
 * Variables
 */
/* Mixins */
/* Animations ($type = bounce, etc.) */
@keyframes bounce { 0% { transform: scale(0); }
  90% { transform: scale(1.3); }
  100% { transform: scale(1); } }
/* Icons (Font-Awesome, etc.) */
/* Grid */
/*!
 * Utilities
 */
.align-baseline { vertical-align: baseline !important; }

.align-top { vertical-align: top !important; }

.align-middle { vertical-align: middle !important; }

.align-bottom { vertical-align: bottom !important; }

.align-text-bottom { vertical-align: text-bottom !important; }

.align-text-top { vertical-align: text-top !important; }

.bg-primary { background-color: #ff1b00 !important; }

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus { background-color: #cc1600 !important; }

.bg-secondary { background-color: #fec200 !important; }

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus { background-color: #cb9b00 !important; }

.bg-success { background-color: #34e16d !important; }

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus { background-color: #1dc554 !important; }

.bg-info { background-color: #00bbce !important; }

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus { background-color: #008d9b !important; }

.bg-warning { background-color: #fec200 !important; }

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus { background-color: #cb9b00 !important; }

.bg-danger { background-color: #ff1b00 !important; }

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus { background-color: #cc1600 !important; }

.bg-light { background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus { background-color: #dae0e5 !important; }

.bg-dark { background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus { background-color: #1d2124 !important; }

.bg-white { background-color: #fff !important; }

.bg-transparent { background-color: transparent !important; }

.border { border: 1px solid #dee2e6 !important; }

.border-top { border-top: 1px solid #dee2e6 !important; }

.border-right { border-right: 1px solid #dee2e6 !important; }

.border-bottom { border-bottom: 1px solid #dee2e6 !important; }

.border-left { border-left: 1px solid #dee2e6 !important; }

.border-0 { border: 0 !important; }

.border-top-0 { border-top: 0 !important; }

.border-right-0 { border-right: 0 !important; }

.border-bottom-0 { border-bottom: 0 !important; }

.border-left-0 { border-left: 0 !important; }

.border-primary { border-color: #ff1b00 !important; }

.border-secondary { border-color: #fec200 !important; }

.border-success { border-color: #34e16d !important; }

.border-info { border-color: #00bbce !important; }

.border-warning { border-color: #fec200 !important; }

.border-danger { border-color: #ff1b00 !important; }

.border-light { border-color: #f8f9fa !important; }

.border-dark { border-color: #343a40 !important; }

.border-white { border-color: #fff !important; }

.rounded { border-radius: 0 !important; }

.rounded-top { border-top-left-radius: 0 !important; border-top-right-radius: 0 !important; }

.rounded-right { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }

.rounded-bottom { border-bottom-right-radius: 0 !important; border-bottom-left-radius: 0 !important; }

.rounded-left { border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; }

.rounded-circle { border-radius: 50% !important; }

.rounded-0 { border-radius: 0 !important; }

.clearfix::after { display: block; clear: both; content: ""; }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }
@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }
@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }
@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }
@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }
.embed-responsive { position: relative; display: block; width: 100%; padding: 0; overflow: hidden; }
.embed-responsive::before { display: block; content: ""; }
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0; }

.embed-responsive-21by9::before { padding-top: 42.8571428571%; }

.embed-responsive-16by9::before { padding-top: 56.25%; }

.embed-responsive-4by3::before { padding-top: 75%; }

.embed-responsive-1by1::before { padding-top: 100%; }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }
@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }
@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }
@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }
.float-left { float: left !important; }

.float-right { float: right !important; }

.float-none { float: none !important; }

@media (min-width: 576px) { .float-sm-left { float: left !important; }
  .float-sm-right { float: right !important; }
  .float-sm-none { float: none !important; } }
@media (min-width: 768px) { .float-md-left { float: left !important; }
  .float-md-right { float: right !important; }
  .float-md-none { float: none !important; } }
@media (min-width: 992px) { .float-lg-left { float: left !important; }
  .float-lg-right { float: right !important; }
  .float-lg-none { float: none !important; } }
@media (min-width: 1200px) { .float-xl-left { float: left !important; }
  .float-xl-right { float: right !important; }
  .float-xl-none { float: none !important; } }
.position-static { position: static !important; }

.position-relative { position: relative !important; }

.position-absolute { position: absolute !important; }

.position-fixed { position: fixed !important; }

.position-sticky { position: sticky !important; }

.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; }

.fixed-bottom { position: fixed; right: 0; bottom: 0; left: 0; z-index: 1030; }

@supports (position: sticky) { .sticky-top { position: sticky; top: 0; z-index: 1020; } }

.sr-only { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus { position: static; width: auto; height: auto; margin: 0; overflow: visible; clip: auto; }

.w-25 { width: 25% !important; }

.w-50 { width: 50% !important; }

.w-75 { width: 75% !important; }

.w-100 { width: 100% !important; }

.h-25 { height: 25% !important; }

.h-50 { height: 50% !important; }

.h-75 { height: 75% !important; }

.h-100 { height: 100% !important; }

.mw-100 { max-width: 100% !important; }

.mh-100 { max-height: 100% !important; }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }
@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }
@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }
@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }
.text-justify { text-align: justify !important; }

.text-nowrap { white-space: nowrap !important; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }
@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }
@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }
@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }
.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.font-weight-light { font-weight: 300 !important; }

.font-weight-normal { font-weight: 400 !important; }

.font-weight-bold { font-weight: 700 !important; }

.font-italic { font-style: italic !important; }

.text-white { color: #fff !important; }

.text-primary { color: #ff1b00 !important; }

a.text-primary:hover, a.text-primary:focus { color: #cc1600 !important; }

.text-secondary { color: #fec200 !important; }

a.text-secondary:hover, a.text-secondary:focus { color: #cb9b00 !important; }

.text-success { color: #34e16d !important; }

a.text-success:hover, a.text-success:focus { color: #1dc554 !important; }

.text-info { color: #00bbce !important; }

a.text-info:hover, a.text-info:focus { color: #008d9b !important; }

.text-warning { color: #fec200 !important; }

a.text-warning:hover, a.text-warning:focus { color: #cb9b00 !important; }

.text-danger { color: #ff1b00 !important; }

a.text-danger:hover, a.text-danger:focus { color: #cc1600 !important; }

.text-light { color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus { color: #dae0e5 !important; }

.text-dark { color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus { color: #1d2124 !important; }

.text-muted { color: #868e96 !important; }

.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }

.visible { visibility: visible !important; }

.invisible { visibility: hidden !important; }

.drive-dev { display: none; }

.link-primary:link, .link-primary:visited { color: #ff1b00; }
.link-primary:hover, .link-primary:active { color: #b31300; }

.btn-link.link-primary { color: #ff1b00; }
.btn-link.link-primary:hover { color: #b31300; }

.link-secondary:link, .link-secondary:visited { color: #fec200; }
.link-secondary:hover, .link-secondary:active { color: #b28800; }

.btn-link.link-secondary { color: #fec200; }
.btn-link.link-secondary:hover { color: #b28800; }

.link-success:link, .link-success:visited { color: #34e16d; }
.link-success:hover, .link-success:active { color: #1aaf4b; }

.btn-link.link-success { color: #34e16d; }
.btn-link.link-success:hover { color: #1aaf4b; }

.link-info:link, .link-info:visited { color: #00bbce; }
.link-info:hover, .link-info:active { color: #007682; }

.btn-link.link-info { color: #00bbce; }
.btn-link.link-info:hover { color: #007682; }

.link-warning:link, .link-warning:visited { color: #fec200; }
.link-warning:hover, .link-warning:active { color: #b28800; }

.btn-link.link-warning { color: #fec200; }
.btn-link.link-warning:hover { color: #b28800; }

.link-danger:link, .link-danger:visited { color: #ff1b00; }
.link-danger:hover, .link-danger:active { color: #b31300; }

.btn-link.link-danger { color: #ff1b00; }
.btn-link.link-danger:hover { color: #b31300; }

.link-light:link, .link-light:visited { color: #f8f9fa; }
.link-light:hover, .link-light:active { color: #cbd3da; }

.btn-link.link-light { color: #f8f9fa; }
.btn-link.link-light:hover { color: #cbd3da; }

.link-dark:link, .link-dark:visited { color: #343a40; }
.link-dark:hover, .link-dark:active { color: #121416; }

.btn-link.link-dark { color: #343a40; }
.btn-link.link-dark:hover { color: #121416; }

/* Elements / Header / Image */
.elements-header-image { background: transparent url("../img/header-xs.jpg") no-repeat center center; background-size: cover; height: 30vh; }
@media screen and (min-width: 576px) { .elements-header-image { background-image: url("../img/header-sm.jpg"); } }
@media screen and (min-width: 768px) { .elements-header-image { background-image: url("../img/header-md.jpg"); } }
@media screen and (min-width: 992px) { .elements-header-image { background-image: url("../img/header-lg.jpg"); } }
@media screen and (min-width: 1200px) { .elements-header-image { background-image: url("../img/header-xl.jpg"); } }
.elements-header-image .overlay { background-color: rgba(255, 27, 0, 0.5); display: flex; align-items: center; height: 100%; color: white; }

.elements-promotion-header h1 { font-weight: 600; }
.elements-promotion-header h1 .emphasis { display: block; font-weight: 700; }
@media (min-width: 576px) { .elements-promotion-header h1 .emphasis { font-size: 4rem; } }
@media (min-width: 992px) { .elements-promotion-header { height: 60vh; } }
@media (min-width: 1200px) { .elements-promotion-header { height: 90vh; } }
.elements-promotion-header .company-start-year { font-size: 1.5rem; }

[class*="elements-header-"], [class*="elements-header-"] .swiper-container .swiper-slide { height: calc(100vh - 76px); }
@media screen and (min-width: 576px) { [class*="elements-header-"], [class*="elements-header-"] .swiper-container .swiper-slide { height: 50vh; } }

.company-start-year { font-family: "Raleway", sans-serif; }

.feature--with-icon { line-height: 1; }
.feature--with-icon img { opacity: 0.5; }
.feature--with-icon .small, .feature--with-icon .feature__statistic, .feature--with-icon .feature__statistic-label { opacity: 0.6; }

.feature__statistic { font-weight: 800; font-size: 2.5rem; }
@media (min-width: 576px) { .feature__statistic { font-size: 4.5rem; } }

.feature__statistic-label { max-width: 8rem; }

[lang="fr-CA"] .feature__statistic-label { max-width: 11rem; }
@media (max-width: 1199.98px) { [lang="fr-CA"] .feature__statistic-label { font-size: 1.5rem; } }

.banner-link { box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); }

.banner-link__icon { max-height: 3rem; }

.banner-link__content { font-size: 14px; flex-grow: 1; }
.banner-link__content p { font-size: inherit; text-align: inherit; margin-bottom: 0; }

.banner-link__arrow { color: #e3e3e3; transition: color 0.2s ease-out; -webkit-text-stroke: 4px white; }

a.banner-link:hover, a.banner-link:active { text-decoration: none; }
a.banner-link:hover .banner-link__arrow, a.banner-link:active .banner-link__arrow { color: #ff1b00; transition: color 0.2s ease-in; }

.banner-link { transition: box-shadow 0.2s ease-out, transform 0.2s ease-out; transform: translateZ(0); backface-visibility: hidden; }
.banner-link * { transform: translateZ(0); backface-visibility: hidden; }

.banner-link:hover { box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3); transform: scale(1.01) translateZ(0); transition: box-shadow 0.2s ease-in, transform 0.2s ease-in; }

.banner-link:active { box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); transform: scale(1); transition: box-shadow 0.2s ease-out, transform 0.2s ease-out; }

.banner-link.ie11 { transform: none; }
.banner-link.ie11:hover { transform: none; }

#facility .fa-ul { font-weight: 600; }

#quick-tour .quick-tour__content-wrapper { background-size: cover; }
#quick-tour .quick-tour__content-wrapper::before { content: ""; display: block; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(0, 0, 0, 0.5); }
#quick-tour .quick-tour__content-wrapper .light-heading { padding-bottom: 2rem; margin-bottom: 2rem; }
#quick-tour .quick-tour__content-wrapper p { text-align: inherit; line-height: 2; hyphens: none; }

#tournaments { position: relative; }
#tournaments::before { position: absolute; content: ""; top: -3rem; height: 100%; width: 100%; background-image: url("/assets/theme/img/graphics/trophy.png"); background-repeat: no-repeat; background-position: 30% center; background-size: contain; }
@media (max-width: 767.98px) { #tournaments::before { background-position: center center; top: 0; height: 90%; } }

#package-promo-footer { margin-top: 4rem; border-top: 0.5rem solid #420e00; position: relative; }
#package-promo-footer:before { position: absolute; content: ""; width: 13rem; height: 13rem; top: -4rem; left: 0; background-image: url("/assets/theme/img/graphics/ball.png"); background-repeat: no-repeat; background-size: contain; z-index: 2; pointer-events: none; }
@media (min-width: 992px) { #package-promo-footer:before { left: 59%; } }
#package-promo-footer::after { position: absolute; content: ""; width: 100%; height: 3rem; top: 0; background-repeat: repeat-x; background-position: 0 center; background-image: radial-gradient(#420e00 10%, transparent 11%); background-size: 4rem 4rem; }
@media (min-width: 576px) { #package-promo-footer::after { background-image: radial-gradient(#420e00 5%, transparent 6%); background-size: 7rem 7rem; } }
@media (min-width: 992px) { #package-promo-footer::after { background-image: radial-gradient(#420e00 5%, transparent 6%); } }


